<template>
    <div style="text-align: center; position: absolute; top:0;right: 0; bottom: 0; left: 0;display: table;">        
        <div style="display:table-cell; vertical-align: middle;">
            <div class="margin-bottom-30">
                <i class="fa fa-internet-explorer icon color-sub"></i>
                <i class="fa fa-ban text-danger fa-2x color-red" style="margin-left:-30px"></i>
            </div>
        
            <div class="title">您当前的浏览器版本过低</div>
            <div class="bold font-size-24 margin-top-10">为了您能更好的使用本系统，请升级浏览器！</div>
            <div class="margin-top-10">推荐使用Google Chrome(谷歌)、Mozilla Firefox(火狐)等新版本浏览器，IE浏览器请用IE9以上版本</div>
            <!-- <div class="margin-top-20 margin-bottom-30">
                <el-button type="primary" size="small" @click="gotoHome">升级浏览器</el-button>
            </div> -->
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {}
    },
    created() {
        
    },
    methods:{
        gotoHome(){
            
        }
    }
}
</script>
<style scoped>
    .icon{
        font-size: 100px;
    }
    .title{
        font-size: 44px;
        font-weight: bold;
    }
</style>